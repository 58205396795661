import {Difference} from "./object/difference.js";
import {filterProps} from "./objects.js";
import {btoaUTF8} from "./base64.js";

/**
 * Transformuje linki z normalnych URLi na dyrektywy TWIG dla zapisu do modelu
 * @param {string} text
 * @return {string}
 */
export const transformFilesUrlsToTwig = (text) => {
    const downloadUrlRegexp = new RegExp(`(src|href)="${POLKURIER.paths.getTemplate('frontFilesDownloadUrl')}(.+?)"`, 'gi');
    const showUrlRegexp = new RegExp(`(src|href)="${POLKURIER.paths.getTemplate('frontFilesShowUrl')}(.+?)"`, 'gi');
    return String(text)
        .replace(downloadUrlRegexp, `$1="{{ downloadUrl('$2') }}"`)
        .replace(showUrlRegexp, `$1="{{ showUrl('$2') }}"`);
};


/**
 * Transformuje linki z dyrektywy TWIG na normalne URL dla zapisu od modelu
 * @param {string} text
 * @return {string}
 */
export const transformFilesUrlsFromTwig = (text) => {
    return String(text)
        .replace(/(src|href)="\{\{ showUrl\('(.+?)'\) \}\}"/gi, `$1="${POLKURIER.paths.getTemplate('frontFilesShowUrl')}$2"`)
        .replace(/(src|href)="\{\{ downloadUrl\('(.+?)'\) \}\}"/gi, `$1="${POLKURIER.paths.getTemplate('frontFilesDownloadUrl')}$2"`);
};

export const dataUrlDecoder = (dataUrl) => {
    if (String(dataUrl).substring(0, 5) !== 'data:') {
        return {
            data: dataUrl,
        };
    }

    let [header, data] = String(dataUrl).substring(5).split(',');
    let [contentType, encoding] = String(header || '').split(';');

    if (encoding === 'base64') {
        data = atob(data);
    }

    if (contentType === 'application/json') {
        data = JSON.parse(data);
    }

    return {
        contentType,
        encoding,
        data,
    }
};

export const dataUrlEncoder = (data, contentType = 'text/plain', encoding = 'base64') => {
    if (typeof data !== 'string' && contentType === 'application/json') {
        data = JSON.stringify(data);
    }
    if (encoding === 'base64') {
        data = btoaUTF8(data);
    }
    return `data:${contentType};${encoding},${data}`;
};

export const extractQueryString = url => {
    url = String(url);
    const i = url.indexOf('?');
    if (i !== -1) {
        return url.substring(i + 1);
    }
    return url;
};

export const removeQueryString = url => {
    url = String(url);
    const i = url.indexOf('?');
    if (i !== -1) {
        return url.substring(0, i);
    }
    return url;
};

/**
 * @param queryString
 * @returns {{}}
 */
export const parseQueryString = queryString => {
    const queryParams = new URLSearchParams(extractQueryString(queryString));
    const params = {};
    let key, value;
    for(let entry of queryParams.entries()) { // each 'entry' is a [key, value] tupple
        [key, value] = entry;
        // Handle simple arrays: ?param[]=value&param[]=value2
        if (key.substr(-2) === '[]') {
            key = key.substr(0, key.length - 2);
            if (!params[key]) {
                params[key] = [];
            } else if (!_.isArray(params[key])) {
                params[key] = [params[key]];
            }
            params[key].push(value);
        } else {
            params[key] = value;
        }
    }
    return params;
};

/**
 * Parsuje przekazany URL i zwraca jego dane jako obiekt
 * @param url
 * @returns {{protocol: *, host: *, hostname: *, port: *, pathname: *, hash: *, search: *, origin: *, params}}
 */
export const parseUrl = url => {
    const parser = document.createElement('a');
    parser.href = url;
    return {
        protocol: parser.protocol, // => "http:"
        host: parser.host,     // => "example.com:3000"
        hostname: parser.hostname, // => "example.com"
        port: parser.port,     // => "3000"
        pathname: parser.pathname, // => "/pathname/"
        hash: parser.hash,     // => "#hash"
        search: parser.search,   // => "?search=test"
        origin: parser.origin,   // => "http://example.com:3000"
        params: parseQueryString(parser.search),
    };
};


/**
 * Podmienia parametry w URL
 * @param params
 */
export const updateUrl = params => {
    history.pushState(params, document.title, `?${jQuery.param(params || {})}`);
};

export const updatePageUrlFromState = (state, defaultState, mapStateToUrl = null, mapUrlToState = null) => {
    const filter = (v, k) => v !== undefined && v !== null && v !== defaultState[k];
    mapUrlToState ??= (props) => props;
    mapStateToUrl ??= (props) => props;
    const currentParams = filterProps(mapStateToUrl(mapUrlToState(parseUrl(document.location.href).params)), filter);
    const nextParams = filterProps(mapStateToUrl(state), filter);
    const diff = new Difference(currentParams, nextParams);
    if (diff.getKeys().length > 0) {
        const path = removeQueryString(document.location.href);
        const nextUrl = Paths.generatePathUrl(path, {
            ...Paths.getDefaultParams(path),
            ...nextParams,
        });
        if (nextUrl !== document.location.href) {
            history.pushState(nextParams, document.title, nextUrl);
        }
    }
};

export class Paths {
    constructor(paths) {
        this.paths = paths || {};
    }

    add(name, path) {
        if (_.isObject(name)) {
            this.paths = {
                ...this.paths,
                ...name
            };
        } else {
            this.paths[name] = path;
        }
        return this;
    }

    get(pathname, params = {}) {
        return Paths.generatePathUrl(this.getTemplate(pathname), params);
    }

    getPath(pathname) {
        const path = this.getTemplate(pathname);
        return path ? new Path(pathname) : null;
    }

    clone() {
        return new Paths(this.all());
    }

    all() {
        return {...this.paths};
    }

    getTemplate(pathname) {
        if (!this.paths[pathname]) {
            throw `Nieznana ścieżka: ${pathname}`;
        }
        return this.paths[pathname];
    }

    static getPathParams(path) {
        return String(path).match(/(:[a-z0-1]+)/gi) || [];
    }

    static getDefaultParams(path) {
        const params = {};
        for (let param of Paths.getPathParams(path)) {
            params[param.replace(/^:/, '')] = '';
        }
        return params;
    }

    static generatePathUrl(path, params) {
        const usedParams = [];
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                path = path.replace(`:${param}`, () => {
                    usedParams.push(param);
                    if (_.isNumber(params[param])) {
                        return params[param] || 0;
                    }
                    return params[param] || '';
                });
            }
        }

        path = path.replace(/\/$/, '');
        let query = jQuery.param(_.omit(params, ...usedParams));
        if (query.length > 0) {
            path += (path.indexOf('?') === -1 ? '?' : '&') + query;
        }
        return path;
    }

    static factory(paths) {
        return paths instanceof Paths ? paths : new Paths(paths);
    }

}


export class Path {

    constructor(path, parameters = {}) {
        this.path = path;
        this.parameters = parameters;
    }

    get parametersTemplate() {
        const params = [];
        const matches = this.path.matchAll(/(:[^/]+)/g);
        for (let match of matches) {
            params.push(match[0]);
        }
        return params;
    }

    get(parameters = {}) {
        return Paths.generatePathUrl(this.path, {
            ...(typeof this.parameters === 'function' ? this.parameters() : this.parameters),
            ...(typeof parameters === 'function' ? parameters() : parameters),
        });
    }

}

export class Url {
    constructor(props = {}) {
        this.protocol = props.protocol || ''; // => "http:"
        this.host = props.host || '';     // => "example.com:3000"
        this.hostname = props.hostname || ''; // => "example.com"
        this.port = props.port || '';     // => "3000"
        this.pathname = props.pathname || ''; // => "/pathname/"
        this.hash = props.hash || '';     // => "#hash"
        this.search = props.search || '';   // => "?search=test"
        this.origin = props.origin || '';   // => "http://example.com:3000"
        this.params = props.params || {};
    }

    static parse(url) {
        return new Url(parseUrl(url));
    }

    setParam(param, value) {
        this.params[param] = value;
        return this;
    }

    setParams(params, merge = true) {
        if (merge === false) {
            this.params = {...params};
        } else {
            this.params = {
                ...this.params,
                ...params,
            }
        }
        return this;
    }

    getQueryString() {
        return jQuery.param(this.params);
    }

    compile() {
        const queryString = this.getQueryString();
        return this.origin + this.pathname + (queryString ? ('?' + queryString) : '');
    }
}

/**
 * UStawia parametry w queryString
 * @param {string} url
 * @param {object} parameters
 * @param {boolean} merge
 * @return {string}
 */
export function setUrlParameters(url, parameters, merge = true) {
    return Url.parse(url).setParams(parameters, merge).compile();
}

/**
 * @param {Object} currentState
 * @param {Object} defaultState
 * @param {String} path
 * @param {Function} mapStateToUrl
 * @param {Function} mapUrlToState
 * @returns {{params: ({}|{}), url: string}|null}
 */
export const getAppStateUrl = (currentState = {}, defaultState = {}, path = '?', mapStateToUrl, mapUrlToState) => {
    mapStateToUrl = mapStateToUrl || (v => v);
    mapUrlToState = mapUrlToState || mapStateToUrl;
    const filter = (v, k) => !v || v === defaultState[k];
    const currentParams = _.omitBy(mapStateToUrl(mapUrlToState(parseUrl(document.location.href).params)), filter);
    const params = _.omitBy(mapStateToUrl(currentState), filter);
    const diff = new Difference(currentParams, params);

    if (diff.getKeys().length > 0) {
        return {
            params,
            url: Paths.generatePathUrl(path || '?', {
                ...Paths.getDefaultParams(path),
                ...params,
            })
        };
    }
    return null;
}

/**
 * Wymusza przesłanie tablic w zmiennej w query string.
 * Jeśli tablica jest pusta to zwrócony zostanie pusty string (jQuery.param domyślnie usuwa puste tablice).
 * @param arr
 * @returns {Array|string|undefined}
 */
export const forceArrayForQueryString = arr => {
    if (arr instanceof Array) {
        return arr.length > 0 ? arr : '';
    }
    return void 0;
};

export const paths = Paths.factory((window && window.POLKURIER && window.POLKURIER.paths) || {});
