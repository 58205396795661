import "core-js";
import '../../../admin/script/libs/polyfills.js';
import "./lib/leaflet.js";
import {PointsMap} from './points-map.js';

if (!window.POLKURIER) {
    window.POLKURIER = {};
}

const link = document.createElement('link');
link.href='https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext';
link.rel='stylesheet'
link.type='text/css';
document.body.appendChild(link);

window.POLKURIER.PointsMap = PointsMap;
