import {EventsEmitter} from '../../events-emitter.js';
import {Store} from '../../store.js';
import {DialogView} from "./views/dialog-view.js";
import {MapView} from "./views/map-view.js";
import {PointsProvider} from "./providers/points-provider.js";
import {AVAILABLE_PROVIDERS, pointNameFormatter, PROVIDER_INTERNATIONAL_SURROGATE} from './providers.js';
import {baseUrl, defaultCountry} from "./config.js";
import {findMaxZIndex} from "../../../admin/script/util/browser.js";
import countiesCenterCoordinates from "./counties-center-coordinates.js";

export class PointsMap {

    static defaultCountry = defaultCountry;

    get state() {
        return this.store.get('pointsMap', {});
    }

    set state(nextState) {
        this.store.merge('pointsMap', nextState);
    }

    /**
     * @param {{
     *     token: string,
     *     baseUrl?: string,
     *     searchQuery?: string,
     *     country?: string,
     *     functions?: Array<string>,
     *     providers?: Array<string>,
     *     selectedProviders?: Array<string>,
     *     latitude?: number,
     *     longitude?: number,
     *     zoom?: number,
     *     zoomButtonsPosition?: 'topleft' | 'topright' | 'bottomright' | 'bottomleft',
     *     showSelectButton?: boolean,
     *     showList?: boolean,
     *     showSearchInput?: boolean,
     *     store?: Store,
     *     popupMargins?: [number, number, number, number],
     * }} props
     */
    constructor(props = {}) {
        this.token = props.token;
        this.baseUrl = props.baseUrl || baseUrl;
        this.store = props.store && props.store instanceof Store ? props.store : new Store();
        const country = String(props.country || defaultCountry).toUpperCase();
        const [defaultLatitude, defaultLongitude] = countiesCenterCoordinates[country] || countiesCenterCoordinates[defaultCountry];

        // Fix na nieprawidłowe przekazywanie providerów jako string
        if (props.providers && !Array.isArray(props.providers)) {
            props.providers = [props.providers];
        }

        this.state = {
            searchQuery: props.searchQuery || '',
            availableProviders: props.providers || AVAILABLE_PROVIDERS,
            selectedProviders: props.selectedProviders || props.providers || AVAILABLE_PROVIDERS,
            functions: props.functions || [],
            latitude: Number(props.latitude) || defaultLatitude,
            longitude: Number(props.longitude) || defaultLongitude,
            zoom: Number(props.zoom) || 16,
            zoomButtonsPosition: String(props.zoomButtonsPosition || '') || undefined,
            showSelectButton: props.showSelectButton !== false,
            showList: props.showList !== false,
            showSearchInput: props.showSearchInput !== false,
            popupMargins: props.popupMargins,
            country,
        };

        this.store.addEventListener(this, {
            'change:pointsMap': currentPoint => {
                this.events.trigger('select:point', currentPoint)
            }
        });

        this.pointsProvider = new PointsProvider({
            store: this.store,
            baseUrl: this.baseUrl,
            token: this.token,
        });
        this.events = new EventsEmitter();
    }

    /**
     * Wyświetla mapkę we wskazanym elemencie HTML
     * @param {HTMLElement} targetElement
     * @returns {PointsMap}
     */
    showMapView(targetElement) {
        if (this.dialog) {
            return this;
        }
        if (!this.mapView) {
            this.mapView = new MapView({
                store: this.store,
                events: this.events,
                pointsProvider: this.pointsProvider,
                token: this.token,
                baseUrl: this.baseUrl,
            });
        }
        this.mapView.setElement(targetElement);
        this.mapView.render();
        return this;
    }

    /**
     * Otwiera okno z mapką
     * @returns {PointsMap}
     */
    showMapDialog() {
        if (this.dialog) {
            return this;
        }
        this.mapView = new MapView({
            store: this.store,
            events: this.events,
            pointsProvider: this.pointsProvider,
            token: this.token,
            baseUrl: this.baseUrl,
        });
        this.dialog = new DialogView({
            fullScreen: true,
        });
        this.dialog.open();
        this.dialog.$modal.style.zIndex = findMaxZIndex() + 1000;
        this.dialog.$modal.classList.add('pk-body', 'pk-points-map-modal');
        this.dialog.$body.classList.add('pk-p-0');
        this.dialog.$closeButton.classList.add('pk-bg-pk', 'pk-bg-pk-hover', 'pk-text-bold', 'pk-text-white', 'pk-border-0', ',pk-cursor-pointer');
        this.dialog.showView(this.mapView);

        this.dialog.onClose(() => {
            this.dialog = null;
            this.mapView = null;
            this.events.trigger('dialog:closed');
        });

        this.dialog.onOpen(() => {
            this.events.trigger('dialog:open');
        });

        return this;
    }

    /**
     * Zamyka okno z mapką
     * @returns {PointsMap}
     */
    closeMapDialog() {
        if (this.dialog) {
            this.dialog.close();
        }
        return this;
    }

    /**
     * Dodaje nasłuch eventów
     * @param {string} event
     * @param {Function} handler
     * @returns {PointsMap}
     */
    on(event, handler) {
        this.events.addEventListener(this, {[event]: handler});
        return this;
    }

    /**
     * Usuwa nasłuch eventów
     * @param {string} event
     * @param {Function} handler
     * @returns {PointsMap}
     */
    off(event, handler) {
        this.events.off(event, handler);
        return this;
    }

    /**
     * Zwraca wybrany punkt
     * @returns {null|Object}
     */
    getSelectedPoint() {
        return this.state.currentPoint;
    }

    /**
     * Callback po wybraniu punktu
     * @param {Function} handler
     * @returns {PointsMap}
     */
    onConfirm(handler) {
        return this.on('confirm:point', handler);
    }

    /**
     * Callback po zaznaczeniu punktu na mapie lub liście
     * @param {Function} handler
     * @returns {PointsMap}
     */
    onSelectPoint(handler) {
        return this.on('select:point', handler);
    }

    /**
     * Ustawia frazę wysdzukiwania
     * @param {string} searchQuery
     * @returns {PointsMap}
     */
    setSearchQuery(searchQuery) {
        this.state = {searchQuery};
        if (this.mapView) {
            this.mapView.performSearchDebounced();
        }
        return this;
    }

    /**
     * Ustawia dostępnych przewoźników
     * @param {string[]} availableProviders
     * @returns {PointsMap}
     */
    setAvailableProviders(availableProviders = []) {
        this.state = {availableProviders};
        return this;
    }

    /**
     * Ustawia wybranych przewoźników
     * @param {string[]} selectedProviders
     * @returns {PointsMap}
     */
    setSelectedProviders(selectedProviders = []) {
        this.state = {selectedProviders};
        return this;
    }

    /**
     * Ustawia pozycję mapy
     * @param {number} latitude
     * @param {number} longitude
     * @param {number} zoom
     * @returns {PointsMap}
     */
    setMapPosition(latitude, longitude, zoom) {
        this.state = {latitude, longitude, zoom};
        if (this.mapView && this.mapView.map) {
            this.mapView.map.setView([latitude, longitude], zoom);
        }
        return this;
    }

    /**
     * Zwraca pozycję środka mapy
     * @returns {[latitude: number, longitude: number, zoom: number]}
     */
    getMapPosition() {
        if (this.mapView && this.mapView.map) {
            const pos = this.mapView.map.getCenter();
            return [pos.lat, pos.lng, this.mapView.map.getZoom()];
        }
        return [this.state.latitude, this.state.longitude, this.state.zoom];
    }

    /**
     * Zwraca wymagane funkcje punktu
     * @returns {Array<string>}
     */
    getPointFunction() {
        return this.state.functions;
    }

    /**
     * Ustawia wymagane funkcje punktu
     * @param {Array<string>} functions
     * @returns {PointsMap}
     */
    setPointFunctionsFilter(functions) {
        if (Array.isArray(functions)) {
            this.state = {functions}
        }
        return this;
    }

    /**
     * Zwraca nazwę wybranego przewoźnika
     * @param {string} providerId
     * @return {string}
     */
    getProviderName(providerId) {
        return pointNameFormatter({provider: providerId});
    }

    /**
     * Włącza wyświetlanie listy punktów
     * @return {string}
     */
    showPointsList() {
        this.state = {showList: true};
        return this;
    }

    /**
     * Ukrywa listę punktów
     * @return {string}
     */
    hidePointsList() {
        this.state = {showList: false};
        return this;
    }

    /**
     *
     * @param {Array<string>} providersIds
     * @return {Array<string>}
     */
    static getProviderInternationalSurrogates(providersIds) {
        // Fix na nieprawidłowe przekazywanie providerów jako string
        if (!Array.isArray(providersIds)) {
            providersIds = [providersIds];
        }
        const surrogates = [];
        for (let providerId of providersIds) {
            if (PROVIDER_INTERNATIONAL_SURROGATE[providerId]) {
                for (let surrogate of PROVIDER_INTERNATIONAL_SURROGATE[providerId]) {
                    if (!surrogates.includes(surrogate)) {
                        surrogates.push(surrogate);
                    }
                }
            }
        }
        return surrogates;
    }

    /**
     * Niszczy widok
     */
    destroy() {
        if (this.dialog) {
            this.dialog.close();
            this.dialog = null;
        }

        if (this.mapView) {
            this.mapView.destroy();
            this.mapView = null;
        }
    }

}
